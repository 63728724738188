import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

import docImg from "../assets/dr-slider.png";
import Logo from "../assets/favicon.png";
import Mixpanel from "../mixpanel";
import { Link } from "react-router-dom";
import { ImCheckboxUnchecked } from "react-icons/im";
import { ImCheckboxChecked } from "react-icons/im";
import { AiFillCheckCircle } from "react-icons/ai";
const BookDemo = () => {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    mobile: "",
    pincode: "",
  });
  const [ischecked, setisChecked] = useState(false);
  const [success, setsuccess] = useState(false);
  const [err, seterr] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
    if (ischecked) {
      Mixpanel.track(`Demo Booking For Doctor`, {
        Name: formData.name,
        Surname: formData.surname,
        MobileNo: formData.mobile,
        Pincode: formData.pincode,
      });
      //   window.location.href = `tel:9902842628`;
      console.log(Mixpanel.track);
      setsuccess(true);
    } else {
      seterr("Agreement for Terms And Conditions is required!!");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="home-container">
        {/* Left Side */}

        <div className="right-side">
          <img src={docImg} alt="AppImage" className="image" />
        </div>
        <div className="left-side">
          {success && (
            <div
              style={{
                backgroundColor: "greenyellow",
                padding: 10,
                borderRadius: 10,
              }}
            >
              <Alert status="success">
                <AiFillCheckCircle color="green" />
                Demo Booked Successfully! Our Team will get back to you soon!
              </Alert>
            </div>
          )}
          <HStack>
            <img src={Logo} alt="AppImage" className="logoImage" />
            <h1 className="title">Cerdoc</h1>
          </HStack>

          <h2 className="subtitle">The Most Convenient Digital Practice App</h2>
          <p className="description">
            Begin your digital practice journey for better clinical outcomes.
          </p>

          <form onSubmit={handleSubmit}>
            <VStack spacing="4" className="Demo">
              <HStack
                style={{
                  display: "flex",
                  justifyContent: "space-between",

                  width: "100%",
                }}
              >
                <FormControl id="name">
                  <FormLabel>Name</FormLabel>
                  <Input
                    className="input"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <FormControl id="surname">
                  <FormLabel>Surname</FormLabel>
                  <Input
                    className="input"
                    type="text"
                    name="surname"
                    value={formData.surname}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </HStack>
              <FormControl id="mobile" style={{ width: "100%" }}>
                <FormLabel>Mobile Number</FormLabel>
                <Input
                  className="input"
                  type="tel"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl id="pincode" style={{ width: "100%" }}>
                <FormLabel>Pincode</FormLabel>
                <Input
                  className="input"
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              {err.length > 0 && (
                <p style={{ color: "red", textAlign: "center" }}>{err}</p>
              )}
              <Checkbox
                spacing="1rem"
                icon={
                  ischecked ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />
                }
                isChecked={ischecked}
                onChange={() => {
                  setisChecked(!ischecked);
                  seterr("");
                }}
              >
                <Link to="/terms">Terms & Conditions</Link>
              </Checkbox>
              <Button type="submit" className="call-button" mt={10}>
                Start Digital Practice
              </Button>
            </VStack>
          </form>
        </div>
      </div>
      <footer className="footer">
        <div className="terms-link">
          <Link to="/terms">Terms and Conditions</Link>
        </div>
      </footer>
    </div>
  );
};

export default BookDemo;
