import React from "react";
import { GoDotFill } from "react-icons/go";
function Terms() {
  return (
    <div className="termscontainer">
      <h1>Terms & Conditions</h1>
      <section className="terms-section">
        <div className="terms-text">
          <p>CONSULTANT AGREEMENT</p>
          <p>
            <br />
            <br />
            Certum Ventures Pvt Ltd, a Company having its registered office at
            9. Hosakerehalli 5th Phase. BSK 3rd Stage. Bengaluru 560085
            (hereinafter referred to as the “Company” or “We” or “Our”, which
            expression shall unless repugnant to the context or meaning thereof
            be deemed to mean its successors-in-interest and permitted assigns)
            of the FIRST PART;
          </p>
          <br />
          <p>AND</p>
          <br />
          <p>
            Practicing Doctor (hereinafter referred to as the “Professional” or
            “You” or “Your”), which expression shall, unless repugnant to the
            context or meaning thereof include its successors, representatives,
            and permitted assigns) of the SECOND PART.
            <br /> <br /> The Company and the Professional shall be individually
            referred to as “Party” and collectively referred to as “Parties”.
            <br />
            <br />
            WHEREAS
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>
            A. The Company is involved in the business of operating a Healthtech
            Platform to help patients seek appointments and consultation from
            doctors/professionals.
            <br /> <br />
            B. The Professional is a qualified doctor licensed to practice as
            per the representations and conditions provided below in this
            Agreement.
            <br /> <br />
            C. The Professional is desirous of enlisting himself/herself on the
            Platform of the Company in order to provide its Services to the
            Users of the Platform.
            <br /> <br />
          </p>
        </div>
        <p>
          NOW, THEREFORE, IN CONSIDERATION OF THE MUTUAL PROMISES AND COVENANTS
          SET FORTH HEREIN AND FOR VALUABLE CONSIDERATION, THE PARTIES HERETO
          AGREE TO BE BOUND BY THE TERMS AND CONDITIONS CONTAINED HEREIN BELOW:
          <br /> <br />
          <br /> <br />
        </p>
        <div className="terms-text terms-list">
          <p>1. DEFINITION</p>

          <p>
            <br /> <br />
            1.1. “Account” shall mean the online account of the Professional
            created on the Platform.
            <br /> <br />
            1.2. “Agreement” shall mean and include Terms and Conditions along
            with all its annexures, schedules, and addendum as may be annexed or
            entered by the Parties in writing from time to time.
            <br /> <br />
            1.3. “Applicable Laws” means any statute, law, rule, ordinance,
            notice, statute, judgment, rule of common law, governmental
            approval, or other governmental restriction or any decree or notice
            in effect on the date of this Agreement or any time after in India.
            <br /> <br />
            1.4. “Confidential Information” shall mean all documents, data,
            communication or information including, but not limited to, all
            tangible, intangible, visual, electronic, present, or future
            information and all other material supplied to or obtained by the
            Professional from the Company or the Platform during the term of
            this Agreement such as (a) financial information; (b) technical
            information, including formulas, layouts, research, development,
            procedures, data, designs, and know-how; (c) business information,
            including operations, planning, marketing materials, promotional
            materials, advertisement materials, promotional offers, incentive
            schemes, business ideas and concepts, market opportunities,
            disclosed by the Company to the Professional; (d) Personal
            information of any of the clients/ customers/ affiliates of the
            Company; (e) all information or data which the Professional has
            access in connection with performance of this Agreement, whether
            before or after execution of this Agreement; and (f) all
            confidential or proprietary concepts, documentation, reports, lists,
            files, data, specifications, customer software, source code, object
            code, flow charts, databases, data files, inventions, information,
            know-how and trade secrets, whether or not patentable or
            copyrightable.
            <br />
            <br />
            1.5. “Intellectual Property Rights” shall include any and all
            applications and registrations for any of the below mentioned in any
            country, arising under any statute or by contract and whether or not
            perfected, currently existing, or hereafter filed, issued, or
            acquired. Intangible Property Rights include any patent of any type,
            design rights, utility models, or other similar invention rights,
            creative designs, copyrights, mask work rights, trade secret or
            confidentiality rights, trademarks, trade names, service marks, and
            any other intellectual property.
            <br />
            <br />
            1.6. “Professional” shall include who shall enrol to provide
            Services on the Platform.
            <br />
            <br />
            1.7. “Platform” shall refer to the website/application of the
            Company having the URL doctor.cerdoc.com or/and the mobile app by
            the name of ‘CerDoc Practice App’.
            <br />
            <br />
            1.8. “Services” shall refer to the services as mentioned in Clause 3
            of this Agreement.
            <br />
            <br />
            1.9. “User” shall mean and include any individual or organization
            who is the user of the Platform.
            <br /> <br />
            <br /> <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>2. INTERPRETATION</p>

          <p>
            <br /> <br />
            For the purpose of this Agreement,
            <br /> <br />
            2.1 headings are for convenience only and do not affect
            construction;
            <br /> <br />
            2.2 except where the context otherwise requires, references to one
            gender include all genders and the singular includes the plural and
            vice-versa; <br /> <br />
            2.3 except where the context otherwise requires, references to any
            enactment shall include references to such enactment as re-enacted,
            amended, or extended and any subordinate legislation made under it;
            <br /> <br />
            2.4 references to persons include companies, corporations,
            partnerships, associations, and other organizations whether or not
            having separate legal personalities;
            <br />
            <br />
            2.5 except where otherwise indicated, reference to clauses,
            sub-clauses, recitals, and annexures of this Agreement;
            <br /> <br />
            2.6 “including” means “including without limitation”; and
            <br />
            <br />
            2.7 if the day on which any act, matter, commission, or thing is to
            be done under or pursuant to this Agreement is not a business day,
            that act, commission, matter, or thing shall be done on the
            preceding business day without fail.
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>3. SERVICES</p>

          <p>
            <br /> <br />
            For the purpose of this Agreement,
            <br /> <br />
            3.1 In lieu of the consideration to be paid as per this Agreement
            and pursuance of the Representation, warranties, terms, and
            conditions, of this Agreement, the Parties are desirous of entering
            into this Agreement in order to fulfill the objectives as detailed
            herein: construction;
            <br />
            <br />
            <span> </span>3.1.1 Appointments and Consultation: The Company shall
            provide a Platform through which the Users can book appointments
            either physically or virtually with the Professional, the
            Professional shall provide the services of medical consultation,
            advisory, medical prescription, etc. to the Users of the Platform.{" "}
            <br /> <br />
            <span> </span>3.1.2 Types of Patients: The Professional through the
            Platform shall get bookings/ appointments of three types of patients
            namely:
            <br /> <br />
            <span> </span> <span> </span>
            <GoDotFill /> Corporate Patients sourced by the Company through the
            tie-ups between the Company and Corporate offices.
            <br /> <br />
            <span> </span> <span> </span>
            <GoDotFill /> Insurance Patients referred to the Platform by
            insurance companies through any tie-ups between the Company and the
            Insurance companies/partners.
            <br /> <br />
            <span> </span> <span> </span>
            <GoDotFill /> The Professional’s own patients or any individual who
            book appointments through the Platform.
            <br /> <br />
            <span> </span> <span> </span>
            <GoDotFill /> Government and other entity sponsored health schemes
            patients.
            <br /> <br />
            <span> </span>3.1.3 The Professional through the Platform shall
            provide the Users based on their condition with a prescription of
            medicine or treatment, lab tests to be conducted, general
            consultation, etc.
            <br /> <br />
            <span> </span>3.1.4 Prescription of Medicines/Lab Tests- The
            Professional while prescribing any medicine or lab tests to the User
            may refer such User to its own pharmacy or labs, in the absence of
            which the Professional shall refer the User to pharmacies and labs
            as listed on the Platform.
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>4. GRANT OF RIGHTS</p>
          <p>
            <br /> <br />
            4.1 Subject to the terms of the Agreement, we grant You and You
            accept a non-exclusive, personal, non-transferable, limited right to
            have access to and to use the Platform for the duration of Your
            engagement with Us.
            <br /> <br />
            4.2 The aforementioned right does not extend to : (a) make the
            Platform, in whole or in part, available to any other person, entity
            or business; (c) modify the contents of the or use such content for
            any commercial purpose, or any public display, performance, sale or
            rental other than envisaged in the Agreement; (c) copy, reverse
            engineer, decompile or disassemble the Platform or the Website, in
            whole or in part, or otherwise attempt to discover the source code
            to the software used in the Platform; or (d) modify the Platform or
            associated software or combine the Platform with any other software
            or services not provided or approved by Us.
            <br /> <br />
            4.3 You will obtain no rights to the Platform except for the limited
            rights to use the Platform expressly granted by this Agreement. Any
            User of the Platform to be referred for in-person consultation at
            the hospital or clinic of the Professional shall be prescribed for
            such meeting and given an appointment over the Platform only.
            <br /> <br />
            4.5 The Platform may contain links or references that direct you to
            third-party websites/applications/content or service providers,
            including advertisers and e-commerce websites (collectively referred
            to as "Third Party Websites"). Please exercise your independent
            judgment and prudence when visiting/using any Third Party Websites
            via a link available on the Platform / Website. Should You decide to
            click on the links to visit such Third Party Websites, You do so of
            Your own volition. Your usage of such Third Party Websites and all
            content available on such Third Party Websites is subject to the
            terms of use of the respective Third Party Website and we is not
            responsible for Your use of any Third Party Websites
            <br /> <br />
            4.6 You agree that, you will acquire, install, configure and
            maintain all hardware, software and communications Platforms
            necessary to access the Platform ("Implementation") and receive the
            Services. To the extent possible, such an assessment should be done
            before making advance payment for the Service. Your Implementation
            will comply with the specifications from time to time established by
            Us. You will ensure that Your Implementation is compatible with the
            Platform and Services. If We notify You that Your Implementation is
            incompatible with the Platform and / or Services, You will rectify
            such incompatibility, and We will have the right to suspend Services
            to You until such rectification has been implemented. Under no
            circumstances will You be eligible for any refund or any financial
            assistance in relation to Implementation.
            <br /> <br />
            4.7 The Company shall have no obligation or responsibility towards
            any employment or engagement of the Professional with any third
            party organization or individual.
            <br /> <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>5. ACCESS TO THE PLATFORM AND USE OF SERVICES</p>
          <p>
            <br /> <br />
            5.1 Verification You agree that Your engagement with us is subject
            to verification by Us of Your identity and credentials as a Doctor
            and to Your ongoing qualification as such. As part of the
            registration process and at any time thereafter, You may be required
            to provide Us with various information such as Your Photo Id, Your
            medical registration details (as recognized by the Medical Council
            of India and Your State Medical Council), Healthcare Professional ID
            (HPID), Your qualifications and other information in order to prove
            that You are a valid health care practitioner in the field that You
            claim( Personal information). We may verify such Personal
            Information or may ask You for additional information. We may also
            make enquiries from third parties to verify the authenticity of Your
            Personal Information. You authorize Us to make such enquiries from
            such third parties, and You agree to hold them and Us harmless from
            any claim or liability arising from the request for or disclosure of
            such information. You agree that We may terminate Your access to or
            use of the Platform and Services at any time if We are unable at any
            time to determine or verify Your Personal Information. We reserve to
            right to carry out re-verification of Personal Information as and
            when required, and the above rights and commitments will extend to
            re-verification as well.
            <br /> <br />
            5.2 Safeguards: You will implement and maintain appropriate
            administrative, physical and technical safeguards to protect the
            Platform from access, use or alteration; and You will always use the
            User ID assigned to You.
            <br />
            5.2.1 You will immediately notify Us of any breach or suspected
            breach of the security of the Platform of which You become aware, or
            any unauthorized use or disclosure of information within or obtained
            from the Platform, and You will take such action to mitigate the
            breach or suspected breach as We may direct, and will cooperate with
            Us in investigating and mitigating such breach.
            <br /> <br />
            5.3 No Third-Party Access. You will not permit any third party to
            have access to the Platform or to use the Platform or the Services
            without Our prior written consent. You will not allow any third
            party to access the Platform or provide information to Users on the
            Platform. You will promptly notify Us of any order or demand for
            compulsory disclosure of health information if the disclosure
            requires access to or use of the Platform or Services.
            <br /> <br />
            5.4 Compliance: You are solely responsible for ensuring that Your
            use of the Platform and the Services complies with applicable law.
            You will also ensure that Your use of the Platform and the Services
            in always in accordance with the terms of the Agreement. You will
            not undertake or permit any unlawful use of the Platform or take any
            action that would render the operation or use of the Platform or
            Services by us.
            <br /> <br />
            5.5 You take the onus and shall be completely liable for any
            prescription of medicines or tests suggested/prescribed by you, the
            Company shall have no liability or no claims against any
            prescription or consultation by You.
            <br /> <br />
            5.6 Cooperation: You will cooperate with Us in the administration of
            the Platform, including providing reasonable assistance in
            evaluating the Platform and collecting and reporting data requested
            by Us for the purposes of administering the Platform.
            <br />
            <br />
            5.7 Providing Physician Data: You agree that We may provide certain
            parts of your Personal Information and information concerning Your
            practice. Such information may identify You but will not identify
            any individual to whom You provide Services. Such information may
            include (without limitation) aggregate data concerning Your
            patients, diagnoses, procedures, orders etc.
            <br />
            <br />
            5.8 Notwithstanding the generality of the foregoing, You shall not
            use the Platform to
            <br /> <br />
            <span> </span>5.8.1 Discriminate in any way between appointments
            booked in the ordinary course and appointments booked through the
            Company.
            <br /> <span> </span>
            5.8.2 Boast of cases, operations, cures or remedies through Platform
            or while providing your Services.
            <br /> <span> </span>
            5.8.3 Directly or indirectly solicit Registered Users to your own
            personal clinic/consultation.
            <br /> <span> </span>
            5.8.4 Give any positive assertion or representation regarding the
            risk-free nature of communicating over online media.
            <br />
            <br />
            5.9 You shall keep Your Personal Information updated and will inform
            Us immediately should any portion of Your Personal Information be
            revoked, is cancelled or expires.
            <br />
            <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>6. USER INFORMATION </p>
          <p>
            <br /> <br />
            6.1 You hereby acknowledge that You may get access to User
            Information including identifiable health related information <br />{" "}
            <br />
            6.2 You represent and warrant that You will, at all times while
            providing the Services and thereafter, comply with all laws directly
            or indirectly applicable to You that may now or hereafter govern the
            collection, use, transmission, processing, receipt, reporting,
            disclosure, maintenance, and storage of User Information, including
            but not limited to the Information Technology Act, 2000 and The
            Information Technology (Reasonable Security Practices and Procedures
            and Sensitive Personal Data or Information) Rules, 2011 made
            thereunder., <br /> <br />
            6.3 Notwithstanding the generality of the aforementioned provision:{" "}
            <br /> <br />
            <span> </span>6.3.1 You acknowledge that You have read, understood
            and agree to comply with the Company's Privacy Policy available at
            ___________ when dealing with User Information.
            <br />
            <span> </span>6.3.2 You represent and warrant that You will not use
            the User Information of Registered Users and Non-Registered Users
            for any other purpose than for providing information to such
            Registered Users and Non-Registered Users and /or fixing
            appointments with the Registered Users.
            <br />
            <span> </span>6.3.3 You shall not bring any claims against the
            Company with respect to breach, leak or unauthorised use/access of
            any User Information.
            <br />
            <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>7. FEES AND CHARGES</p>
          <p>
            <br /> <br />
            7.1 The Company charges the Users for booking appointments and
            consultation with You(hereinafter referred to as “Consultation
            Fees”), Your Consultation Fees to be charged and collected by the
            User shall be set in mutual agreement between both Parties, You
            shall not be allowed to set any fees arbitrarily without the express
            written consent of the Company as the Company needs to set Fees as
            per its policy in a manner to provide affordable Services to its
            Users.
            <br /> <br />
            7.2 The Consultation Fees and other charges that a User may have to
            pay towards medicines, tests, treatments, etc. shall be as agreed
            upon between company and service provider.
            <br /> <br />
            7.3 User Fee: After the year initial 12 months of signing up on the
            Platform, You shall be liable to pay the Company a User Fee
            amounting to INR 3650 + GST as applicable, to the Company on an
            annual basis.
            <br /> <br />
            7.4 Variable fee: The Company shall at its sole discretion pay a
            variable fee to you on a monthly basis as an incentive based on the
            following parameters to be solely decided by the Company, these
            shall include but not be limited to your performance, feedback
            received from the Users of the Platform, antibiotic stewardship,
            quality certificate or any other form of certification and approvals
            by various statutory and medical bodies, accolades received,
            facilities provided by you at your clinic, etc. Payment and
            frequency of variable fee shall be solely at the discretion of the
            Company, you shall not bring any claims against the Company for the
            payment of variable fee.
            <br /> <br />
            7.5 The Company shall receive all Consultation Fees paid by any User
            in its bank accounts along with any taxes and charges as may be
            applicable. The Company shall retain any statutory taxes, charges,
            and withholding fees as per the Applicable laws. The Consultation
            Fees collected by the Company on your behalf from Registered Users
            shall be disbursed to your bank after deducting applicable taxes.
            <br /> <br />
            7.6 The Company shall disburse the Consultation Fees collected for a
            month by the 10th of every subsequent month.
            <br /> <br />
            7.7 You hereby grant Us written consent to collect, store, handle
            and use Your financial information such as bank account or credit
            card or debit card or other payment instrument details for the
            purpose of paying for Services and access to Platform in accordance
            with Our Privacy Policy.
            <br /> <br />
            7.8 In case the Company is of the opinion that the consultation
            provided by you to a User against the Consultation Fees is
            unsatisfactory or deficient in some way, then it reserves the right
            to (a) where the corresponding Consultation is unpaid, forfeit the
            said fees or (b) where the corresponding Professional’s Share is
            paid, (i) recover the corresponding Professional’s Share by
            adjusting the amount equivalent to Professional’s Share against any
            future payments that may be due or (ii) make a claim of the
            corresponding Professional’s Share on you. You agree that upon
            receipt to a claim from the Company, you will pay the amount to the
            Company within fifteen (15) days from the date of the claim.
            <br /> <br />
            <br /> <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>8. INTELLECTUAL PROPERTY RIGHTS</p>
          <p>
            <br /> <br />
            8.1 All intellectual property rights in and title to the Platform,
            the present or future modifications/upgradations thereof, and
            standard enhancements thereto shall remain the property of The
            Company Inc. and its licensors. This Agreement does not and shall
            not transfer any ownership or proprietary interest in the Platform
            from The Company to You, except as may be otherwise expressly
            provided in these terms or as may be agreed to by and between the
            Parties.
            <br /> <br />
            <br /> <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>9. CONFIDENTIAL INFORMATION</p>
          <p>
            <br /> <br />
            9.1 You will treat all information received from Us as confidential.
            You may not disclose Our confidential information to any other
            person, and You may not use any confidential information except as
            provided herein. Except as otherwise provided in this Agreement, you
            may not, without Our prior written consent, at any time, during or
            after the applicability of this Agreement, directly or indirectly,
            divulge or disclose confidential information for any purpose or use
            confidential information for Your own benefit or for the purposes or
            benefit of any other person. You agree to hold all confidential
            information in strict confidence and to take all measures necessary
            to prevent unauthorized copying, use, or disclosure of confidential
            information, and to keep the confidential information from being
            disclosed into the public domain or into the possession of persons
            not bound to maintain confidentiality. You will promptly notify Us
            in writing of any improper disclosure, misappropriation, or misuse
            of the confidential information by any person, which may come to
            Your attention.
            <br /> <br />
            9.2 You agree that We will suffer irreparable harm if You fail to
            comply with the obligations set forth in this Clause 9, and You
            further agree that monetary damages will be inadequate to compensate
            Us for any such breach. Accordingly, You agree that We will, in
            addition to any other remedies available to Us at law or in equity,
            be entitled to seek injunctive relief to enforce the provisions
            hereof, immediately and without the necessity of posting a bond.
            <br /> <br />
            9.3 Clause 9 will survive the termination or expiration of this
            Agreement or Agreement for any reason.
            <br />
            <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>10. REPRESENTATIONS AND WARRANTIES</p>
          <p>
            <br /> <br />
            10.1 The Professional hereby represents and warrants that he/she:
            <br />
            <span> </span>10.1.1 has the relevant skill, know-how,
            qualifications, experience, and expertise to perform Services in
            accordance with this Agreement.
            <br />
            <span> </span>10.1.2 is legally competent to enter into this
            Agreement and perform their obligations under this Agreement.
            <br />
            <span> </span>10.1.3 will comply with all applicable laws,
            regulatory requirements, and standards in connection with the
            performance of the Services, and will not do or permit anything to
            be done which might cause or otherwise result in a breach of this
            Agreement or cause any detriment to the transactions herein
            envisaged; and
            <br />
            <span> </span>10.1.4 does not violate or contravene any other
            agreement or terms by getting into this Agreement.
            <br />
            <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>11. DISCLAIMER AND EXCLUSION OF WARRANTIES</p>
          <p>
            <br /> <br />
            11.1 You acknowledge that access to the platform will be provided
            over various facilities and communication lines, and information
            will be transmitted over local exchange and internet backbone
            carrier lines and through routers, switches, and other devices
            (collectively, "carrier lines") owned, maintained, and serviced by
            third-party carriers, utilities, and internet service providers, all
            of which are beyond our control. we assume no liability for or
            relating to the integrity, privacy, security, confidentiality, or
            use of any information while it is transmitted on the carrier lines,
            or any delay, failure, interruption, interception, loss,
            transmission, or corruption of any data or other information
            attributable to transmission on the carrier lines. use of the
            carrier lines is solely at your risk and is subject to all
            applicable local, state, national, and international laws.
            <br /> <br />
            11.2 The Services, the Platform, access to the Platform, and the
            information contained on the Platform is provided "as is" and "as
            available" basis without any warranty of any kind, expressed or
            implied, including but not limited to the implied warranties of
            merchantability, fitness for a particular purpose, and
            non-infringement. You are solely responsible for any and all acts or
            omissions taken or made in reliance on the platform or the
            information in the platform, including inaccurate or incomplete
            information. it is expressly agreed that in no event shall we be
            liable for any special, indirect, consequential, remote or exemplary
            damages, including but not limited to, loss of profits or revenues,
            loss of use, or loss of information or data, whether a claim for any
            such liability or damages is premised upon breach of contract,
            breach of warranty, negligence, strict liability, or any other
            theory of liability, even if we have been apprised of the
            possibility or likelihood of such damages occurring. We disclaim any
            liability for erroneous transmissions and loss of service resulting
            from communication failures by telecommunication service providers
            or the Platform.
            <br /> <br />
            11.3 The actions of any Users are beyond our control. Accordingly,
            We do not assume any liability for or relating to any impairment of
            the privacy, security, confidentiality, integrity, availability, or
            restricted use of any information on the Platform resulting from any
            Users' actions or failures to act.
            <br /> <br />
            11.4 We are not responsible for unauthorized access to Your, data,
            facilities or equipment by individuals or entities using the
            Platform or for unauthorized access to, alteration, theft.
            corruption, loss or destruction of Your, data files, programs,
            procedures, or information through the Platform, whether by
            accident, fraudulent means or devices, or any other means. We are
            not responsible for the content of any information transmitted or
            received through our provision of the Services or Platform.
            <br /> <br />
            11.5 We expressly disclaim any liability for the consequences to you
            arising because of your use of the Platform or the Services.
            <br /> <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>12. LIMITATION OF LIABILITY</p>
          <p>
            <br /> <br />
            12.1 Notwithstanding the other terms of this Agreement, in the event
            the Company should have any liability to you or any third party for
            any loss, harm or damage, you and the company agree that such
            liability shall under no circumstances exceed the value of any
            Consultation Fees received by the Company on your account in the
            preceding 3 months or INR _____ whichever is lower. You and the
            company agree that the foregoing limitation of liability is an
            agreed allocation of risk between you and the Company.
            <br /> <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>13. NON-SOLICIT </p>
          <p>
            <br /> <br />
            13.1 The Professional along with his family members, hereby agrees
            and undertakes that during the Term of this Agreement and thereof
            for a period of ___________ years from the date of termination as
            mentioned in this Agreement, the Professional shall not, directly or
            indirectly, in any capacity or function:
            <br /> <br />
            <span> </span>13.1.1 seek, canvas, solicit or accept or provide from
            or to any person who is a User, any Services or business of the type
            offered by the Company and/or its affiliates.
            <br /> <br />
            <span> </span>13.1.2 seek to employ, solicit, or entice away, or
            encourage anyone else to do so, from the Company and its affiliates,
            any employee, who is currently employed by the Company.
            <br /> <br />
            <span> </span>13.2 The Professional agrees that during the term of
            this Agreement and for the restricted period of __________________
            years the Professional shall not other than the capacity of
            performing his/her duties interfere or attempt to interfere with the
            relationship between the Company and its employees, Professional,
            independent contractors, Users , suppliers, and vendors etc.
            <br /> <br />
            13.3 Divert or attempt to divert any business of the Company.
            <br /> <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>14. INDEMNIFICATION</p>
          <p>
            <br /> <br />
            14.1 You agree to indemnify, defend, and hold harmless the Company,
            its affiliates, officers, directors, and agents, from and against
            any claim, cost or liability, including reasonable attorneys' fees,
            arising out of: (a) the use of the Services; (b) any breach by You
            of any representations, warranties or agreements contained in this
            Agreement ; (c) the actions of any person gaining access to the
            Platform under a User ID assigned to You; (d) the actions of anyone
            using a User ID, password or other unique identifier assigned to You
            that adversely affects the Platform or any information accessed
            through the Platform; (e) any claim bought against you by any
            User/customer or your current or previous employers or any third
            party to who you render similar Services.
            <br /> <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>15. TERMINATION; MODIFICATION; SUSPENSION; TERMINATION</p>
          <p>
            <br /> <br />
            15.1 Either Party may terminate this Agreement at any time without
            cause upon thirty (30) days prior written notice to the other Party.
            <br /> <br />
            15.2 Termination, Suspension or Amendment as a result of applicable
            laws - Notwithstanding anything to the contrary in this Agreement,
            We have the right, on providing notice to You, immediately to
            terminate, suspend, or amend the provision of the Services without
            liability: (a) to comply with any order issued or proposed to be
            issued by any governmental agency; (b) to comply with any provision
            of law, any standard of participation in any reimbursement program,
            or any accreditation standard; or (c) if performance of any term of
            this Agreement by either Party would cause it to be in violation of
            law.
            <br /> <br />
            15.3 We may terminate the provision of Services to You through the
            Platform immediately upon notice to You: (i) if You are named as a
            defendant in a criminal proceeding for a violation of central or
            state law; (ii) if a finding or stipulation is made or entered into
            that You have violated any standard or requirement of federal or
            state law relating to the privacy or security of health information
            is made in any administrative or civil proceeding; or (iii) You
            cease to be qualified to provide services as a health care
            professional, or We are unable to verify Your qualifications as
            notified to Us under this Agreement.
            <br /> <br />
            15.4 We may suspend Your Services immediately pending Your cure of
            any breach of this Agreement, or in the event We determine in Our
            sole discretion that access to or use of the Platform by You may
            jeopardize the Platform or the confidentiality, privacy, security,
            integrity or availability of information within the Platform, or
            that You have violated or may violate this Agreement or Other Terms,
            or has jeopardized or may jeopardize the rights of any third party,
            or that any person is or may be making unauthorized use of the
            Platform with any User ID assigned to You. Our election to suspend
            the Services shall not waive or affect Our rights to terminate this
            Agreement as applicable to You as permitted under this Agreement.
            <br /> <br />
            15.5 Upon termination, You will cease to use the Platform and We
            will terminate Your access to the Platform. Upon termination for any
            reason, You will remove all software provided under this Agreement
            from Your computer platforms, You will cease to have access to the
            Platform, and You will return to Us all hardware, software and
            documentation provided by or on behalf of Us.
            <br /> <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>16. DISPUTE RESOLUTION & GOVERNING LAW</p>
          <p>
            <br /> <br />
            16.1 The Parties shall resort and endeavor to arrive at an amicable
            settlement in good faith and mutual understanding in the event any
            matter or dispute pertaining to the enforcement of any rights and
            obligations under this Agreement arises between them. However, on
            failure to do so all the disputes or differences so arising between
            the Parties shall be settled through Mediation failing which the
            dispute shall be settled by arbitration in accordance with the
            provisions of the Arbitration and Conciliation Act, 1996.
            <br />
            <br />
            16.2 The arbitration shall be conducted by a sole arbitrator. The
            venue of arbitration shall be at ______.
            <br />
            <br />
            16.3 The award given by the arbitrator shall be final and binding on
            the Parties. . <br />
            <br />
            <br />
            16.4 The costs pertaining to the arbitration shall be borne by the
            Parties in accordance with the award passed by the arbitrator.
            <br />
            <br />
            16.5 Subject to the foregoing arbitration clause the rights and
            obligations of the Parties hereunder shall be governed by and
            construed and interpreted in accordance with the laws of India and
            the Parties hereto submit to the exclusive jurisdiction of the
            courts, situated at ______.
            <br />
            <br />
          </p>
        </div>
        <div className="terms-text terms-list">
          <p>17. MISCELLANEOUS</p>
          <p>
            <br /> <br />
            17.1 Modification: We may update or change the Services and/or the
            Terms of this Agreement set forth in this Agreement from time to
            time and recommend that You review this Agreement on a regular
            basis. You understand and agree that Your continued use of the
            Services after the Terms of the Agreement has been updated or
            changed constitutes Your acceptance of the revised Agreement.
            Without limiting the foregoing, if We make a change to this
            Agreement that materially affects Your use of the Services, We may
            post notice on the Website or notify You via email of any such
            change.
            <br />
            <br />
            17.2 Severability: In the event, any part of this Agreement is
            declared illegal or unenforceable, the Parties hereto will cooperate
            in all ways open to them, including taking appropriate steps to
            amend, modify or alter this Agreement.
            <br />
            <br />
            17.3 Assignment: You shall not assign this Agreement to any other
            party and shall adhere to the requirements under this Agreement. We
            reserve the right to assign any and all obligations under this
            Agreement without seeking any prior approval from You. <br />
            <br />
            <br />
            IN WITNESS WHEREOF, the Professional (service provider) in complete
            legal capacity agreed on these terms and conditions by clicking the
            checkbox and submitting the same.
            <br /> <br />
          </p>
        </div>
      </section>
    </div>
  );
}

export default Terms;
