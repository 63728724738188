import React, { useState } from "react";
import { HStack } from "@chakra-ui/react";

import docImg from "../assets/home.png";
import Logo from "../assets/favicon.png";
import Mixpanel from "../mixpanel";
import { Link, useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const [phone, setPhone] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [errText, seterrText] = useState("");

  const DemoBook = async () => {
    window.location.href = `tel:9902842628`;
    const nameWithoutDr = name.replace("Dr. ", "");
    const [firstName, ...lastNameArray] = nameWithoutDr.split(" ");
    const lastName = lastNameArray.join(" ");

    console.log(
      "name :",
      firstName,
      "surname :",
      lastName,
      "phone :",
      phone,
      pinCode
    );

    if (name === "") {
      seterrText("Name and SurName must be specified");
    } else if (phone === "") {
      seterrText("Mobile Number must be specified");
    } else if (pinCode === "") {
      seterrText("PinCode must be specified");
    } else {
      Mixpanel.track(`Demo Booking For Doctor`, {
        Name: firstName,
        Surname: lastName,
        MobileNo: phone,
        Pincode: pinCode,
      });

      console.log(Mixpanel.track);
    }

    // console.log(name.replace(/^Dr\. /i, ''));
    // console.log("Hello",mixpanel);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="home-container">
        {/* Left Side */}
        <div className="left-side">
          <HStack>
            <img src={Logo} alt="AppImage" className="logoImage" />
            <h1 className="title">Cerdoc</h1>
          </HStack>

          <h2 className="subtitle">The Most Convenient Digital Practice App</h2>
          <p className="description">
            Begin your digital practice journey for better clinical outcomes.
          </p>
          <button className="demo-button" onClick={() => navigate("/demo")}>
            Book a Demo
          </button>
          <div className="input-group">
            <input
              type="text"
              placeholder="Name"
              className="input"
              defaultValue="Dr. "
              onChange={(e) => {
                setName(e.target.value);
                seterrText("");
              }}
            />
            <input
              placeholder="Mobile Number"
              className="input"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                seterrText("");
              }}
              type="tel"
            />
            <input
              type="text"
              placeholder="Pincode"
              className="input"
              value={pinCode}
              onChange={(e) => {
                setPinCode(e.target.value);
                seterrText("");
              }}
            />
            <button className="call-button" onClick={() => DemoBook()}>
              Call Now
            </button>
          </div>
        </div>

        {/* Right Side */}
        <div className="right-side">
          <img src={docImg} alt="AppImage" className="image" />
        </div>
      </div>
      <footer className="footer">
        <div className="terms-link">
          <Link to="/terms">Terms and Conditions</Link>
        </div>
      </footer>
    </div>
  );
};

export default Home;
